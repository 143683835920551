// hint-mixins.scss
//
// Place to store common mixins.

// Vendor prefixer mixin.
@mixin vendor($property, $value) {
	-webkit-#{$property}: $value;
	-moz-#{$property}: $value;
	#{$property}: $value;
}

// Generates border-color rules for all 4 positions
@mixin arrow-border-color($color, $isInsideSelector: "true") {
	@each $position in top, bottom, left, right {
		// if the current mixin is called from within a selector, use a '&'. Otherwise not.
		@if $isInsideSelector == "true" {
			&.#{$prefix}#{$position}:before {
				border-#{$position}-color: $color;
			}
		}
		@else {
			.#{$prefix}#{$position}:before {
				border-#{$position}-color: $color;
			}
		}
	}
}


// mixin to set margin on tooltip using translate transform
@mixin set-margin($property, $transitionDirection) {
	$value: unquote("#{$property}(#{$transitionDistance * $transitionDirection})");
	&:after, &:before {
		@include vendor('transform', $value);
	}
}
