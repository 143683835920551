@mixin box-shadow($value) {
    -webkit-box-shadow: $value;
    -moz-box-shadow: $value;
    box-shadow: $value;
}

@mixin linear-gradient($fromColor, $toColor) {
  background-color: $toColor; /* Fallback Color */
  background-image: -webkit-gradient(linear, left top, left bottom, from($fromColor), to($toColor)); /* Saf4+, Chrome */
  background-image: -webkit-linear-gradient(top, $fromColor, $toColor); /* Chrome 10+, Saf5.1+, iOS 5+ */
  background-image:    -moz-linear-gradient(top, $fromColor, $toColor); /* FF3.6 */
  background-image:     -ms-linear-gradient(top, $fromColor, $toColor); /* IE10 */
  background-image:      -o-linear-gradient(top, $fromColor, $toColor); /* Opera 11.10+ */
  background-image:         linear-gradient(top, $fromColor, $toColor);
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,StartColorStr='#{$fromColor}', EndColorStr='#{$toColor}');
}

@mixin radial-gradient($from, $to) {
    background: -moz-radial-gradient(center, circle cover, $from 0%, $to 100%);
    background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, $from), color-stop(100%, $to));
    background: -webkit-radial-gradient(center, circle cover, $from 0%, $to 100%);
    background: -o-radial-gradient(center, circle cover, $from 0%, $to 100%);
    background: -ms-radial-gradient(center, circle cover, $from 0%, $to 100%);
    background: radial-gradient(center, circle cover, $from 0%, $to 100%);
    background-color: $from;
}

