@mixin triangle{
display:block;
 width: 15px;
 height: 15px;
 -webkit-transform: rotate(-45deg);
 -moz-transform: rotate(-45deg);
 -o-transform: rotate(-45deg);
 transform: rotate(-45deg);
}

$org-color-7:$secondary;
$org-color-1:#FF8F1A;
$org-color-2:#C3742F;
$org-color-6:#004D56;
$org-color-3:#004376;
$org-color-4:#00637B;
$org-color-5:#007D82;
$org-color-0:darken($secondary,15%);

.events .panelleft h1{
text-align:center;
}

.events .panelleft{padding-bottom:1em;overflow:visible;}

table.smd_cal_calendar {
    border-collapse: collapse;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    background: none;

    td {
        color: #aaa;
        text-shadow: none;
        text-align: center;
        padding: 8px;
        @include linear-gradient(rgba(0, 0, 0, .15), rgba(0, 0, 0, 0));
    
        &:hover {
          @include linear-gradient(rgba(0, 0, 0, .2), rgba(0, 0, 0, 0));
                            background-color: lighten($global-heading-color, 35%);

        }
    
        &.smd_cal_hols,
        &.smd_cal_today {
            color: white;
          @include linear-gradient(rgba(0, 0, 0, .25), rgba(0, 0, 0, 0));
                  background-color: lighten($global-heading-color, 35%);
                  h4{
                    color: darken($global-heading-color, 20%);
                  }
        }
    }
    
    th {
        font-weight: bold;
        color: #fff;
        padding: 3px 0px;
        border: 0px;
        text-align: center;
        font-size: 11px;
        @include linear-gradient(rgba(0, 0, 0, .25), rgba(0, 0, 0, 0));
        background-color: darken($global-heading-color, 5%);
        width: 14.28%;  
    
        a {
            font-size: 16px;
            text-align: center;
            color: #FFF;
            border: 0px;
        }
    }
    
    a {
        color: #ccc;
        font-weight: bold;
        display: block;
        text-decoration: none;
        text-align: center;
        padding: 8px 0;
        margin: -7px 0 -7px;
        border: none;
    
        &:hover {
            color: white;
        }
    }
}

table form#smd_caldate {
    font-size: 14px;
    text-align: center;
}

table &.smd_cal_navprev,
table &.smd_cal_navnext {
    font-size: 18px;
    border: none;
    padding: 0px 0 5px;
    margin: 0;
    color: #888 !important;
}

table tr.smd_cal_daynames {
    border-top: 0px solid #111;
    border-bottom: 2px solid rgba(0, 0, 0, 0.5);
}

/* ....{ Biggie Specific }........................ */
    
#bigCal {
  margin-top: 2em;
  table{
    width:100%;
  
   th {
       font-size: 14px;
       
      &[colspan="5"]{font-size:1.5rem;}
       a {
           border: none;
           color: #fff !important;
           opacity: .8;
           font-size: 18px;
           vertical-align: middle;
  
           &:hover {
               opacity: 1;
           }
       }
  
       form {
           padding: 10px;
       }
      }
  
   h4 {
       font-size: 1rem;
       margin: 0;
       padding:0;
       text-align: right;
       font-weight: bold;
       color: $global-heading-color;
   }
   
   td {
       vertical-align: top;
       padding: 5px;
       height: 100px;
       border-bottom: 0px solid rgba(0, 0, 0, 0.1);
   &.smd_cal_empty {
    opacity:.5;
   }
       &.smd_cal_hols {
           background-color: #EFE8D2;
           background-position: 0 -130px;
           text-align: center;
           padding: 10px 20px;
       }
   
       &.smd_cal_hols:after {
           content: "Facility Reserved";
           color: #6D683E;
           text-align: center;
           font-weight: bold;
           text-shadow: none;
           line-height: 100%;
       }
   
       &.smd_cal_today {}
   
   
       &.today h4 {
           color: #ffffff;
       }
   
       &.smd_cal_event {
           padding: 10px 5px 5px;
           background-color: rgba(0, 0, 0, 0.05);
           line-height: 140%;
           &.smd_cal_multiprev div.event, 
           &.smd_cal_multifirst div.event {
               margin-right: -5px !important;
               border-radius: 7px 0px 0px 7px !important;
           }
           &.smd_cal_multilast div.event {
               margin-left: -5px !important;
               border-radius: 0 7px 7px 0px !important;
           }
           &.smd_cal_multi div.event {
               border-radius: 0 !important;
               margin-left: -5px;
               margin-right: -5px;
           }
           div.event {
               position: relative;
               clear: both;
               padding: 8px;
               margin: 3px 5px;
               border-radius: 7px;
               border: none;
                 background: darken($global-heading-color,10%);
                 opacity:.8;
               font-weight: bold;
               &:hover {
                   opacity: 1;
               }
               p {
                   margin-bottom: 0;
                   font-size: 10px;
                   line-height: 110%;
                   margin-bottom: 5px;
               }
               a {
                   border: none;
                   font-weight: normal;
               }
               &:hover .eventDetails {
                   display: block;
               }
               .eventDetails {
                   display: none;
                   position: absolute;
                   z-index: 9500;
                   left: 0;
                   > div {
                       margin: -7px -8px 0;
                       padding: 10px;
                       border-radius: 7px;
                       background: rgba(0, 0, 0, 0.5);
                       text-align: left;
                       font-size: 105%;
                       text-align: center;
                   }
                   &:before {
                       content: "▲";
                       color: rgba(0, 0, 0, 0.2);
                       font-size: 1.4em;
                   }
                   p {
                       text-align: left;
                       padding: 0px 5px;
                       font-weight: normal;
                       a {
                           color: white;
                       }
                   }
                   div p {
                       margin: 0;
                       color: #aaa;
                       &.eventDetailHeader {
                           margin-top: 10px;
                       }
                   }
                   .extended a {
                       -moz-border-radius: 5px;
                       -khtml-border-radius: 5px;
                       border-radius: 5px;
                       margin: 10px 5px 3px;
                       width: auto;
                   }
               }
           }
           .extended {
               a {
                   font-size: 10px;
                   line-height: 110%;
                   margin-bottom: 5px;
               }
           }
       }
   }
}
}
 .events{
    .date {
        -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.4);
        -moz-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.4);
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.4);
        border: 5px solid white;
        float: none;
        clear: both;
        text-align: center;
        margin: 0 auto 20px;
        width: 75px;
        a{
        text-decoration:none !important;
        }
        .month {
            margin: 0;
            font-size: .6rem;
            padding: 3px 0 0;
            text-align: center;
            letter-spacing: .3px;
            font-weight: bold !important;
            //background: $secondary;
            color: white;
        }
        .day {
            display: block;
            clear: both;
            text-align: center;
            font-size: 2.3rem;
            line-height: 1.25em;
            border-top: 2px solid rgba(255, 255, 255, .15);
            @include linear-gradient(rgba(0, 0, 0, .2), rgba(0, 0, 0, 0));
        }
    }
}

// Tooltips ........................................................................
a.tooltip {
 outline: none;
 text-decoration: none;
 position: relative;
 line-height:120%;
 } 
a.tooltip strong {
 } 
a.tooltip > span {
text-align:left;
  font-size:150%;
left:-10px;
 padding: 10px 20px;
 margin-top: 0;
 opacity: 0;
 visibility: hidden;
 z-index: 10;
 position: absolute;
 border-radius: 8px;
 -webkit-transition-property: opacity, margin-top, visibility, margin-left;
 -webkit-transition-duration: 0.2s, 0.15s, 0.2s, 0.15s;
 -webkit-transition-timing-function: ease-in-out, ease-in-out, ease-in-out, ease-in-out;
 transition-property: opacity, margin-top, visibility, margin-left;
 transition-duration: 0.2s, 0.15s, 0.2s, 0.15s;
 transition-timing-function: ease-in-out, ease-in-out, ease-in-out, ease-in-out;
 }
a.tooltip:hover > span {
 opacity: 1;
 text-decoration: none;
 visibility: visible;
 overflow: visible;
 margin-top: 30px;
 display: inline;
 margin-left: 0px;
 } 

a.tooltip p{
  font-size:.9rem;
    font-weight:normal;
color:#ddd;
text-shadow:none;
strong{color:white;}
}

 a.tooltip span i{

height:8px; 
width:28px; 
overflow: hidden;
display:block; 
margin-left: 40px;
margin-top: -21px;
display: block;
position: absolute;
padding:3px 0 0 3px;
}
a.tooltip span b {
display:block;
 width: 15px;
 height: 15px;
 -webkit-transform: rotate(-45deg);
 -moz-transform: rotate(-45deg);
 -o-transform: rotate(-45deg);
 transform: rotate(-45deg);
 /*
 -webkit-box-shadow: inset -1px 1px 0 #fff;
 -moz-box-shadow: inset 0 1px 0 #fff;
 -o-box-shadow: inset 0 1px 0 #fff;
 box-shadow: inset 0 1px 0 #fff;
 
 */
} 



a.tooltip > span,
a.tooltip span b {
background:rgba(0,0,0,.7);
 }




.event-org-list {
   text-align: center;
   line-height:300%;
   li {
       display: inline-block;
       .show-me {
        position:relative;
           background: none;
           font-size: 100%;
           color: rgba(255, 255, 255, .6);
           opacity: .7;
           border:none;
           transition:all 0.3s ease;
           text-transform:capitalize;

           &.selected,
           &:hover {
               opacity: 1;
               color: white;
               cursor:pointer;
           }

           b{
           transition:top 0.3s ease;
           top:80%;
           }
           &.selected b{
              width: 0; 
              height: 0; 
              border-left: 10px solid transparent;
              border-right: 10px solid transparent;
              border-top: 10px solid $org-color-0;
              top:100%;
              position:absolute;
              left:45%
           }
       }
   }
}


li.all a,
td div.general,
.general .date .month {
    background: $org-color-0 !important;
}

td div.club,
.club .date .month,
li.club a {
    background: $org-color-7 !important;
    b {
        border-color: $org-color-7 transparent transparent !important;
    }
}


td div.education,
.education .date .month,
li.education a {
    background: $org-color-1 !important;
    b {
        border-color: $org-color-1 transparent transparent !important;
    }
}

td div.government,
.government .date .month,
li.government a {
    background: $org-color-2 !important;
    b {
        border-color: $org-color-2 transparent transparent !important;
    }
}

td div.religious,
.religious .date .month,
li.religious a {
    background: $org-color-3 !important;
    b {
        border-color: $org-color-3 transparent transparent !important;
    }
}

td div.restaurant,
.retaurant .date .month,
li.restaurant a {
    background: $org-color-4 !important;
    b {
        border-color: $org-color-4 transparent transparent !important;
    }
}

td div.retail,
.retail .date .month,
li.retail a {
    background: $org-color-5 !important;
    b {
        border-color: $org-color-5 transparent transparent !important;
    }
}

td div.service,
.service .date .month,
li.service a {
    background: $org-color-6 !important;
    b {
        border-color: $org-color-6 transparent transparent !important;
    }
}


