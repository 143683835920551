// ======================================================================
// Midas
// ======================================================================
//
// A small SASS powered kickstarter for RWD
// Brought to you by @CodeStalker - midas.jamessteel.co.uk

// ======================================================================
// Typography
// ======================================================================

@import "../config";

@font-face {
  font-family: 'Raleway';
  src: url('fonts/Raleway-Regular.eot'); /* IE9 Compat Modes */
  src: url('fonts/Raleway-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('fonts/Raleway-Regular.woff') format('woff'), /* Modern Browsers */
       url('fonts/Raleway-Regular.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('fonts/Raleway-Regular.svg#f8ac7b0ae9ec6d45be568052f50b9149') format('svg'); /* Legacy iOS */
       
  font-style:   normal;
  font-weight:  400;
}

@font-face {
  font-family: 'Raleway';
  src: url('fonts/Raleway-SemiBold.eot'); /* IE9 Compat Modes */
  src: url('fonts/Raleway-SemiBold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('fonts/Raleway-SemiBold.woff') format('woff'), /* Modern Browsers */
       url('fonts/Raleway-SemiBold.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('fonts/Raleway-SemiBold.svg#27d695091e6c815cf171653bd8e07de1') format('svg'); /* Legacy iOS */
       
  font-style:   normal;
  font-weight:  700;
}

@font-face {
    font-family: 'socicon';
    src: url('fonts/socicon-webfont.eot');
    src: url('fonts/socicon-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/socicon-webfont.woff') format('woff'),
         url('fonts/socicon-webfont.ttf') format('truetype'),
         url('fonts/socicon-webfont.svg#sociconregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-family: 'icomoon';
  src:url('fonts/icomoon.eot?-i1v459');
  src:url('fonts/icomoon.eot?#iefix-i1v459') format('embedded-opentype'),
    url('fonts/icomoon.woff?-i1v459') format('woff'),
    url('fonts/icomoon.ttf?-i1v459') format('truetype'),
    url('fonts/icomoon.svg?-i1v459#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

// ======================================================================
// File Icons
// ======================================================================



.filelist {
	
	a {

	font-family: 'icomoon';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	
	}

	a[href$='.pdf']:before
	
		{content: "\e60f"; padding: 0 10px 0 0;}
		
	a[href$='.doc']:before,
	a[href$='.docx']:before
	
		{content: "\e610"; padding: 0 10px 0 0;}
		
	a[href$='.excel']:before
	
		{content: "\e611"; padding: 0 10px 0 0;}
		
	a[href$='.zip']:before,
	a[href$='.rar']:before,
	
		{content: "\e612"; padding: 0 10px 0 0;}
		
	a[href$='.ppt']:before,
	a[href$='.pptx']:before,
	a[href$='.pptm']:before
	
		{content: "\e613"; padding: 0 10px 0 0;}	
		
		
	
		
	
}


@font-face {
  font-family: 'icomoon';
  src:url('fonts/icomoon.eot?t5jxr9');
  src:url('fonts/icomoon.eot?#iefixt5jxr9') format('embedded-opentype'),
    url('fonts/icomoon.woff?t5jxr9') format('woff'),
    url('fonts/icomoon.ttf?t5jxr9') format('truetype'),
    url('fonts/icomoon.svg?t5jxr9#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-list:before {
  content: "\e600";
}
.icon-arrow-left:before {
  content: "\e601";
}
.icon-arrow-down:before {
  content: "\e602";
}
.icon-arrow-up:before {
  content: "\e603";
}
.icon-arrow-right:before {
  content: "\e604";
}
.icon-cog:before {
  content: "\e605";
}
.icon-globe:before {
  content: "\e606";
}
.icon-mail:before {
  content: "\e607";
}
.icon-google-plus:before {
  content: "\e608";
}
.icon-facebook:before {
  content: "\e609";
}
.icon-instagram:before {
  content: "\e60a";
}
.icon-twitter:before {
  content: "\e60b";
}
.icon-vimeo:before {
  content: "\e60c";
}
.icon-picassa:before {
  content: "\e60d";
}
.icon-tumblr:before {
  content: "\e60e";
}
.icon-file-pdf:before {
  content: "\e60f";
}
.icon-file-word:before {
  content: "\e610";
}
.icon-file-excel:before {
  content: "\e611";
}
.icon-file-zip:before {
  content: "\e612";
}
.icon-file-powerpoint:before {
  content: "\e613";
}




// ======================================================================
// Basic
// ======================================================================

html {
  font-size: 75%;
  -webkit-font-smoothing: $webkit-smoothing;
}

@media only screen and (min-width: 320px)  { html { font-size: 81.25%; }} 			
@media only screen and (min-width: 480px)  { html { font-size: 87.5%;  }}
@media only screen and (min-width: 640px)  { html { font-size: 95%;   }}
@media only screen and (min-width: 960px)  { html { font-size: 105%; }}
@media only screen and (min-width: 1440px) { html { font-size: 115%;   }}

body {
  font-size: 	$global-font-size;
  line-height: 	$global-line-height;
  font-weight: 	$global-font-weight;
  color: 		$global-font-color;
  font-family: 	$global-font-stack;
}

// ======================================================================
// Headings
// ======================================================================

h1,h2,h3,h4,h5,h6 {
  //margin: 0 0 1.414rem 0;
  margin: 0 0 .5rem 0;
  line-height: $global-line-height;
  color: $global-heading-color;
  font-weight: $global-font-weight;
  text-rendering: optimizeLegibility;
  font-family: $global-heading-stack;
  text-transform: uppercase;
}

h1,h2,h3{  text-shadow: 1px 1px  #e5e5e5;
}

// Values below use the perfect fourth ratio.
// Generated with Typescale.com - http://bit.ly/1lxW2J8



// ======================================================================
// Paragraphs
// ======================================================================

p {margin-top:0;
  margin-bottom: 1.3rem; 
  color: $gray;
}

// ======================================================================
// Tables
// ======================================================================

// table {
//   border-collapse: collapse;
//   position: relative;
//   table-layout: fixed;
//   width: 100%;
//   tr,th,td {
//     margin: 0;
//   }
//   td {
//     line-height: $global-line-height;
//   }
// }

// thead,tfoot,tbody {
//   width: 100%;
// }

// caption {
//   line-height: $global-line-height;
// }

// ======================================================================
// Quotes
// ======================================================================

blockquote {
  padding: 0;
  margin: 0;
  line-height: $global-line-height;
}

blockquote > p {
  padding: 0;
}

// ======================================================================
// Misc
// ======================================================================

a {
  text-decoration: none;
  color: $link;
}

a:hover {
  text-decoration: underline;
  color: $link-hover;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid $dark;
  margin: 1.5rem 0 1.5rem 0;
  padding: 0;
}

sup, sub {
  vertical-align: baseline;
  position: relative;
  top: -0.4rem;
}

sub {
  top: 0.4rem;
}

strong {
  font-weight: $extrabold; color:$global-heading-color;
}

rem,dfn,cite,q {
  line-height: $global-line-height;
}

dfn {
  font-weight: $extrabold;
}

pre,code,tt,kbd {
  font-family: $terminal;
}

pre {
  white-space: pre;
}

pre,code,tt {
  font-size: 0.9rem;
  line-height: $global-line-height;
}

tt {
  display: block;
  margin: 1.5rem 0;
}

kbd {
  position: relative;
  top: -1px;
  margin-bottom: 1px;
  padding: 0 0;
  font-size: 0.9rem;
}

@media only screen and (min-width: 480px) {
  kbd {
    padding: 0.1rem 0rem;
    font-size: 0.75rem;
  }
}

pre {
  word-break: break-all;
  word-wrap: break-word;
  white-space: pre;
  white-space: -moz-pre-wrap;
  white-space: pre-wrap;
  white-space: pre\9;
}


// ======================================================================
// Social icons
// ======================================================================

.socicon {
  position: relative;
  display: inline-block;
  font-family: 'socicon';
  font-style: normal;
  font-weight: normal;
  line-height: 1.5rem;
  -webkit-font-smoothing: antialiased;
  background-color: $global-heading-color;
  text-align: center;
  width:1.5rem;
  height: 1.5rem;
  color: lighten($global-heading-color, 30%);

 

  
  &:hover {text-decoration: none; 
      background-color: darken($global-heading-color,10%);
}
  
}
.socicon:empty{
    width: 1.5em;
}



			
.socicon-globe:before {
	font-family: 'icomoon';
	content: "\e606";
}	

/*
.socicon-easid:before { content: "5"; }
*/
.socicon-twitter:before { content: "a"; }
.socicon-facebook:before { content: "b"; }
.socicon-google:before { content: "c"; }
/*
.socicon-pinterest:before { content: "d"; }
.socicon-foursquare:before { content: "e"; }
.socicon-yahoo:before { content: "f"; }
.socicon-skype:before { content: "g"; }
.socicon-yelp:before { content: "h"; }
.socicon-feedburner:before { content: "i"; }
.socicon-linkedin:before { content: "j"; }
.socicon-viadeo:before { content: "k"; }
.socicon-xing:before { content: "l"; }
.socicon-myspace:before { content: "m"; }
.socicon-soundcloud:before { content: "n"; }
.socicon-spotify:before { content: "o"; }
.socicon-grooveshark:before { content: "p"; }
.socicon-lastfm:before { content: "q"; }
.socicon-youtube:before { content: "r"; }
.socicon-vimeo:before { content: "s"; }
.socicon-dailymotion:before { content: "t"; }
.socicon-vine:before { content: "u"; }
.socicon-flickr:before { content: "v"; }
.socicon-500px:before { content: "w"; }
.socicon-instagram:before { content: "x"; }
.socicon-wordpress:before { content: "y"; }
.socicon-tumblr:before { content: "z"; }
.socicon-blogger:before { content: "A"; }
.socicon-technorati:before { content: "B"; }
.socicon-reddit:before { content: "C"; }
.socicon-dribbble:before { content: "D"; }
.socicon-stumbleupon:before { content: "E"; }
.socicon-digg:before { content: "F"; }
.socicon-envato:before { content: "G"; }
.socicon-behance:before { content: "H"; }
.socicon-delicious:before { content: "I"; }
.socicon-deviantart:before { content: "J"; }
.socicon-forrst:before { content: "K"; }
.socicon-play:before { content: "L"; }
.socicon-zerply:before { content: "M"; }
.socicon-wikipedia:before { content: "N"; }
.socicon-apple:before { content: "O"; }
.socicon-flattr:before { content: "P"; }
.socicon-github:before { content: "Q"; }
.socicon-chimein:before { content: "R"; }
.socicon-friendfeed:before { content: "S"; }
.socicon-newsvine:before { content: "T"; }
.socicon-identica:before { content: "U"; }
.socicon-bebo:before { content: "V"; }
.socicon-zynga:before { content: "W"; }
.socicon-steam:before { content: "X"; }
.socicon-xbox:before { content: "Y"; }
.socicon-windows:before { content: "Z"; }
.socicon-outlook:before { content: "1"; }
.socicon-coderwall:before { content: "2"; }
.socicon-tripadvisor:before { content: "3"; }
.socicon-netcodes:before { content: "4"; }
.socicon-lanyrd:before { content: "7"; }
.socicon-slideshare:before { content: "8"; }
.socicon-buffer:before { content: "9"; }
.socicon-rss:before { content: ","; }
.socicon-vkontakte:before { content: ";"; }
.socicon-disqus:before { content: ":"; }
*/
