// ======================================================================
// Midas
// ======================================================================
//
// A small SASS powered kickstarter for RWD
// Brought to you by @CodeStalker - midas.jamessteel.co.uk

// ======================================================================
// Config
// ======================================================================

$pesticide-debug: false;

// Colors

$bright: 	#7e8668;
$medium: 	#696f56;
$dark: 		darken($medium, 10%);
$gray: #777;

$primary: 	#fff;
$secondary: $bright;
$tertiary: 	#D1CEC2;

// Weights

$ultralight: 	100;
$thin: 			200;
$light: 		300;
$regular: 		400;
$semibold: 		500;
$bold: 			600;
$extrabold: 	700;
$heavy: 		800;
$ultraheavy: 	900;

// Smoothing

$webkit-smoothing: antialiased;

// Fonts

$global-font-size: 		1rem;
$global-line-height: 	1.5;

$global-font-stack: 	$raleway;
$global-font-weight: 	$thin;
$global-font-color: 	$gray;

// Headings
$global-heading-stack: 	$raleway;
$global-heading-color: 	$bright;
$global-heading-weight: $regular;

// Links

$link:			$medium;
$link-hover:	darken($global-heading-color, 20%);
