.accordion-container {
	
	padding: 1rem 0 0 0;
}

.accordion-header {
	max-width:1200px; margin:0 auto;
	cursor: pointer;
	height:80px;
	line-height:80px;
	text-indent: 50px;
	// border-bottom: 1px solid $secondary;
	
	&:hover {
		
	}

}

.active-header {
		&:before{content:"▼  ";}
}

.inactive-header {
		&:before{content: "► " !important;}
}

.accordion-header{
			color:$secondary;		
			&:hover{color:darken($secondary,10%) !important;}
}


.accordion-content {
	display: none;
	padding: 0;
	
}









//wine in filter table

.wine-header {cursor: pointer; text-indent: 20px;}

.w-accordion-header {
	max-width:1200px; margin:0 auto;
	cursor: pointer;
	height:80px;
	line-height:80px;
	// border-bottom: 1px solid $secondary;
	
	&:hover {
		//color:$tertiary;
		
	}

}

.w-active-header {

	background-image: url(../images/icons/sactive.png);
	background-repeat: no-repeat;
	background-position: 1% 50%;
	color:$tertiary;

}

.w-active-header:hover {
	background-image: url(../images/icons/sactive.png);
	background-repeat: no-repeat;
	background-position: 1% 50%;
	//color:$tertiary;
}

.w-inactive-header {
	background-image: url(../images/icons/sinactive.png);
	background-repeat: no-repeat;
	background-position: 1% 50%;
}

.w-inactive-header:hover {
	background-image: url(../images/icons/sinactive.png);
	background-repeat: no-repeat;
	background-position: 1% 50%;
}

.w-accordion-content {
	display: none;
	padding: 0;
	
}





@media only screen and (max-width: 768px)  { 

.w-active-header {

	background-image: none;


}

.w-active-header:hover {
	background-image: none;

}

.w-inactive-header {
	background-image: none;

}

.w-inactive-header:hover {
	background-image: none;

}

}





.dir-header {
	max-width:1200px; margin:0 auto;
	cursor: pointer;
	height:80px;
	line-height:80px;
	text-indent: 30px;
	// border-bottom: 1px solid $secondary;
	
	&:hover {
		//color:$tertiary;
		
	}

}

.d-active-header {

	background-image: url(../images/icons/sactive.png);
	background-repeat: no-repeat;
	background-position: 1% 50%;
	//color:$tertiary;
}

.d-active-header:hover {
	background-image: url(../images/icons/sactive.png);
	background-repeat: no-repeat;
	background-position: 1% 50%;
	//color:$tertiary;
}

.d-inactive-header {
	background-image: url(../images/icons/sinactive.png);
	background-repeat: no-repeat;
	background-position: 1% 50%;
}

.d-inactive-header:hover {
	background-image: url(../images/icons/sinactive.png);
	background-repeat: no-repeat;
	background-position: 1% 50%;
}

.dir-content {
	display: none;

}


	





