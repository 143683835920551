.footable {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  
  color: $bright;
  border-bottom: 1px solid $secondary;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  table-layout: fixed;

}

.footable > tbody {font-size:0.75rem;}



.footable.breakpoint > tbody > tr.footable-row-detail {
  background: $secondary;
}
.footable.breakpoint > tbody > tr:hover:not(.footable-row-detail) {
  cursor: pointer;
}
.footable.breakpoint > tbody > tr > td.footable-row-detail-cell {
  background: $secondary;
}
.footable > tbody img {
  vertical-align: middle;
}
.footable > tbody > tr:hover:not(.footable-row-detail) {
  background: rgba(0,0,0,.08);
}
.footable > tbody > tr:hover:not(.footable-row-detail) > td {
}
.footable > tbody > tr:last-child > td {
  border-bottom: none;
}
.footable > tbody > tr > td {
  border-top: 1px solid $secondary;
  padding: 10px;
  text-align: left;
  border-left: none;
  vertical-align: top;
}
.footable > tbody > tr > td.footable-row-detail-cell,
.footable > tbody > tr > td.footable-first-column {
  border-left: none;
}
.footable > thead > tr > th {
  
  padding: 10px;
  text-align: left;
}
.footable > thead > tr > th,
.footable > thead > tr > td {
  background-color: $primary;
  font-size:0.85rem;
  color: #fff;
  font-weight: 700;
  border-top: none;
  border-left: none;
  font-weight: normal;
}
.footable > thead > tr > th.footable-first-column,
.footable > thead > tr > td.footable-first-column {
  border-left: none;
}
.footable > tfoot > tr > th,
.footable > tfoot > tr > td {
  background-color: $primary;
 
  color: #ffffff;
  border-top: none;
  border-left: none;
  
}
.pagination {
  text-align: center;
}
.pagination ul {
  display: inline-block;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  vertical-align: middle;
  margin: 1rem 0 1rem 0;
  padding: 0;
  position: relative;
  width: auto;
  overflow: hidden;
  font-size: 0.8rem;
  line-height: 0.8rem;
}
.pagination ul > li {
  display: inline;
  
}
.pagination ul > li > a,
.pagination ul > li > span {
  float: left;
  color: #ffffff;
  background: transparent;
  display: block;
  width:1.75rem;
  height:1.75rem;
  line-height: 1.75rem;
  opacity: 0.5;
  text-decoration: none;
  background-color: $dark;
  font-weight: 400;
transition: all .3s ease;
}

.pagination ul > li > a:hover {opacity: 1.0;}

.pagination ul > li.disabled > a {
  color: #888;
}
.pagination ul > li.active > a {
  color: #222;
  background-color: $secondary;
}
.pagination ul > li:first-child > a,
.pagination ul > li:last-child > a,
.pagination ul > li:first-child > span,
.pagination ul > li:last-child > span {
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}


.footable.metro-blue {
  border-color: #ddd;
}
.footable.metro-blue > tfoot > tr > th,
.footable.metro-blue > thead > tr > th,
.footable.metro-blue > tfoot > tr > td,
.footable.metro-blue > thead > tr > td {
  background-color: lighten($secondary,10%);
  border-color: gray;
}
.footable-odd {
  background-color: rgba(0,0,0,.05);
}

.footable.metro-blue > thead > tr > th:first-child{
  width:15% !important;
 }



.footable td ul { margin:0 0 0 0; padding:0 0 0 0;

  li {list-style:none; border-bottom:1px solid #3a3a3a; padding:0.5rem 0 0.5rem 0;}
  li:last-child {border-bottom:none;}
}


.footable strong{color:$secondary;}