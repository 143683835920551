// ======================================================================
// Midas
// ======================================================================
//
// A small SASS powered kickstarter for RWD
// Brought to you by @CodeStalker - midas.jamessteel.co.uk

// ======================================================================
// Font Stacks
// ======================================================================

$times: 		Times, "Times New Roman", serif;
$arial: 		Arial, sans-serif;
$georgia: 		Georgia, serif;
$garamond: 		"Garamond", "Apple Garamond", "ITC Garamond Narrow", serif;
$helvetica: 	"Helvetica Neue", Helvetica, sans-serif;
$verdana: 		"Verdana Ref", Verdana, sans-serif;
$trebuchet: 	"Trebuchet MS", sans-serif;
$stimes: 		Cambria, "Hoefler Text", Utopia, "Liberation Serif", "Nimbus Roman No9 L Regular", "Times New Roman", Times, serif;
$sgeorgia: 		Constantia, "Lucida Bright", Lucidabright, "Lucida Serif", Lucida, "DejaVu Serif", "Bitstream Vera Serif", "Liberation Serif", Georgia, serif;
$sgaramond: 	"Palatino Linotype", Palatino, Palladio, "URW Palladio L", "Book Antiqua", Baskerville, "Bookman Old Style", "Bitstream Charter", "Nimbus Roman No9 L", "Apple Garamond", "ITC Garamond Narrow", "New Century Schoolbook", "Century Schoolbook", "Century Schoolbook L", Garamond, serif;
$shelvetica: 	Frutiger, "Frutiger Linotype", Univers, Calibri, "Gill Sans", "Gill Sans MT", "Myriad Pro", Myriad, "DejaVu Sans Condensed", "Liberation Sans", "Nimbus Sans L", Tahoma, Geneva, "Helvetica Neue", Helvetica, sans-serif;
$sverdana: 		Corbel, "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", "DejaVu Sans", "Bitstream Vera Sans", "Liberation Sans", "Verdana Ref", Verdana, sans-serif;
$strebuchet: 	"Segoe UI", Candara, "Bitstream Vera Sans", "DejaVu Sans", "Bitstream Vera Sans", "Trebuchet MS", Trebuchet, sans-serif;
$sgillsans: 	Frutiger, "Frutiger Linotype", Univers, Calibri, "Myriad Pro", Myriad, "DejaVu Sans Condensed", "Liberation Sans", "Nimbus Sans L", Tahoma, Geneva, "Gill Sans MT", "Gill Sans", sans-serif;
$terminal: 		Monaco, "Lucida Sans Typewriter", Consolas, "Courier New", monospace;
$raleway: 		'Raleway',Frutiger, "Frutiger Linotype", Univers, Calibri, "Myriad Pro", Myriad, "DejaVu Sans Condensed", "Liberation Sans", "Nimbus Sans L", Tahoma, Geneva, "Gill Sans MT", "Gill Sans", sans-serif;

