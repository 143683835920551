// ======================================================================
// Midas
// ======================================================================
//
// A small SASS powered kickstarter for RWD
// Brought to you by @CodeStalker - midas.jamessteel.co.uk
// ======================================================================
// Partials
// ======================================================================
@import "partials/mixins";
@import "partials/libs/normalize";
@import "partials/libs/grid";
@import "partials/menu";
@import "partials/collapse";
@import "partials/table";
@import "partials/table-theme";
@import "partials/slider";
@import "partials/stacks";
@import "partials/type";
@import "partials/events";
@import "partials/magnific_pop";
@import "partials/responsiveslides";
@import "partials/tooltip/hint";
@import "partials/GITheWall";


// ======================================================================
// Debug
// ======================================================================
@import "partials/libs/pesticide";
// ======================================================================
// Vars
// ======================================================================
$total_columns: 12;
$total_width: 100%;
$gutter_width: 2%;
$container_margin: 2%;
$class_container: "row";
$class_column: "col";
$class_push: "push";
// ======================================================================
// Common
// ======================================================================
@mixin center {
    max-width: 1200px;
    margin: 0 auto;
}

@mixin halfcenter {
    max-width: 768px;
    margin: 0 auto;
}

img {
    display: block;
    margin: 0 auto;
    width: 100%;
    height: auto
}

.home {
    display: none;
}

@media only screen and (min-width: 320px) {
    .home {
        margin: 0 0 0 1rem;
        width: 140px;
        height: 43px;
        display: block;
        float: left;
        line-height: 50px;
        text-indent: -9999px;
        opacity: 0.6;
        background-image: url(../images/community_small.png);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        &.discovery {
            background-image: url(../images/discovery_small.png);
        }
        &:hover {
            text-decoration: none;
            opacity: 1.0;
        }
    }
}

.hidden {
    display: none !important;
}

// ======================================================================
// Footer
// ======================================================================
footer {
    padding: 10px 0;
    background-color: darken($tertiary, 5%);
    width: 100%;
    height: 30px;
    position: fixed;
    bottom: 0;
    left: 0;
    @include box-shadow(0 -10px 15px 0 rgba(0, 0, 0, .35));
    .footer-strip {
        @include container();
        @include center {}
        p {
            text-align: center;
            margin: 0;
            color: lighten($secondary, 5%);
            strong {
                color: $secondary;
            }
        }
        a {
            color: $secondary;
            text-decoration: none !important;
            &:hover {
                color: white;
            }
        }
    }
}

// ======================================================================
// Design
// ======================================================================
html,
body {
    margin: 0;
    padding: 0;
    height: 100%;
    background-color: #EBE8E1;
    & > .wrapper {
        min-height: 100%;
        height: 100%;
        position: relative;
    }
    .content {
        height: 80%;
        padding-bottom: 50px;
        background: #EBE8E1 url(../images/page_bg.png) top left repeat-x !important;

        & > h1{text-align:center;padding-top:2rem;}
    }
}

// BANNER
#banner {
    width: 100%;
    .banner-mask {
        position: absolute;
        /* background-color: fuchsia; */
        
        left: 0;
        top: 0;
        z-index: 500;
        width: 100%;
        height: 100%;
        /* background-image: url(../images/banner-tint.png); */
        
        background-color: #000;
        opacity: 0.55;
        background-repeat: repeat;
    }
    max-height: 260px;
    @media only screen and (min-width: 480px) {
        max-height: 150px;
    }
    @media only screen and (min-width: 640px) {
        max-height: 200px;
    }
    @media only screen and (min-width: 960px) {
        max-height: 400px;
    }
    overflow: hidden;
    position:relative;
    .logos {
        @include container();
        position: absolute;
        z-index: 1000;
        overflow: hidden;
        .leaf {
            @include column(12);
            a {
                display: block;
                max-width: 725px;
                margin: 0 auto;
                opacity: 0.8;
                &:hover {
                    opacity: 1.0;
                }
            }
            img {
                max-width: 725px;
            }
        }
    }
}

// NAV
nav {
    min-height: 50px;
    padding: 0.5rem 0 0.5rem 0;
    @include legacy-pie-clearfix();
    background-color: $secondary;
    border-bottom: 5px solid darken($secondary, 5%);
    z-index: 1000;

    @media only screen and (min-width: 640px) {
        @include box-shadow(0 10px 10px 0 rgba(0, 0, 0, .3));

    }
}

.safari nav,
.fixto-fixed {
    z-index: 1000;
}

// Panel
.panelleft {
    padding: 4rem 0 4rem 0;
    overflow: hidden;
    @include container();
    @include center {}
    .image, .copy {
        @include column(12);
        clear: both;
    }
    @media only screen and (min-width: 640px) {
        .image {
            @include column(4);
            clear: none;
        }
        .copy {
            @include column(7);
            @include push(5);
            clear: none;
        }
    }
}

// pager nav
.slidepanel .article-navs {
    top: -25px;
}
.article-navs.item-1{
    padding-top:2rem;
}
.article-navs {
    position: relative;
    z-index: 1 !important;
    display: table;
    padding: 10px 0;
    width: 100%;
    text-align: center;
    a {
        color: $primary;
        background: $link;
        border-radius: 13px;
        display: inline-block;
        width: 25px;
        height: 25px;
        text-align: center;
        vertical-align: middle;
        opacity: .6;
        font-size: 20px !important;
        &:hover {
            opacity: 1;
            text-decoration: none;
        }
        span {
            border: 0px !important;
        }
    }
    a.article-edit {
        font-size: 13px !important;
        span {
            display: block;
            margin: 6px 0 0 1px;
        }
    }
}

#item-1 {
    //background: #EBE8E1 url(../images/page_bg.png) top left repeat-x;
}

.wrapper .content {
    padding-bottom: 50px;
}

// Panel
.panelright {
    padding: 4rem 0 4rem 0;
    overflow: hidden;
    @include container();
    @include center {}
    .image, .copy {
        @include column(12);
        clear: both;
    }

    @media only screen and (min-width: 640px) {
        .copy {
            @include column(7);
            clear: none;
        }
        .image {
            @include column(4);
            @include push(8);
            clear: none;
        }
    }
}

// Location Panel
$gutter_width: 0%;
$container_margin: 0%;
.locate {
    @include container();
    overflow: hidden;
    label {}
    .filter {
        @include center {}
        margin-bottom: 1rem;
        label {
            text-transform: uppercase;
            display: inline-block;
            margin: 0 1rem 0 0;
            width: auto;
        }
        input {
            border: none;
            border-bottom: 1px solid $secondary;
            background-color: transparent;
            margin: 0 1rem;
            font-weight: 400;
        }
        input:focus {
            outline: none;
        }
        p {
            margin: 0 0 0 0;
            padding: 0 0 0 0;
            text-align: center;
        }
    }
    .data {
        //@include center {}
    }
    span.footable-sort-indicator {
        margin-left: 3%;
        font-size: 75%;
        opacity: .3;
        &:hover, &:active {
            opacity: 1;
        }
    }
}

$gutter_width: 2%;
$container_margin: 2%;
// Stats
.statwrap {
    background-image: url(/assets/images/stat-bk.jpg);
    background-size: cover;
    h2 {
        clear: both;
    }
}

.stats {
    padding: 4rem 0 2rem 0;
    overflow: hidden;
    @include container();
    @include center {}
    .numbers {
        @include column(12);
        clear: both;
    }
    @media only screen and (min-width: 640px) {
        .number {
            @include column(4);
            clear: none;
        }
    }
    span {
        width: 100%;
        font-size: 18px;
        text-transform: uppercase;
        margin: 0 0 1rem 0;
    }
    .statbox {
        overflow: hidden;
        margin: 0 0 1rem 0;
    }
    img {
        float: left;
        max-width: 40px;
        margin: 0 1rem 0 0;
    }
    .stat {
        font-size: 40px;
        margin: 0 0 0 0;
        padding: 0 0 0 0;
        line-height: 40px;
        color: $bright
    }
    .stat span {
        font-size: 40px;
        margin: 0 0 0 0;
        padding: 0 0 0 0;
        line-height: 40px;
        color: $bright
    }
    .stat span.after {
        margin-left: .5rem;
        font-size: 50%;
    }
}

// Wine Slider
/*
.slidepanel {


		padding:4rem 0 4rem 0;
		overflow: hidden;
		@include container();
		
		.slide-holder {
		@include center {}

	}

		.image,.copy {@include column(12); clear: both;}
		
		@media only screen and (min-width: 640px)  {

		.image {@include column(2); clear: none;}
		.copy {@include column(10);@include push(2);clear: none;}

		}

}
*/

.slidepanel {
    padding: 4rem 0 4rem 0;
    overflow: hidden;
    @include container();
    .slide-holder {
        @include halfcenter {}
    }
    /*
		.image,
		.copy { width: 100%; clear: both;}
		
		@media only screen and (min-width: 768px)  {

		.image {width: 100px; float: left; clear: none;}
		.copy {  float: right; clear: none; width: 100%; max-width: 458px;}

		}
		
		.tapbk {max-width: 280px; margin: 0 auto;
			img {max-width: 280px;}
			
			width: 280px; height: 1230px; position: relative;
			
			.sticker {width: 190px;  position: absolute; z-index: 1; left: 45px; top:645px;}
			
		}
		
*/
    
    .slick-next,
    .slick-prev {
        font-size: 200%;
    }
    .slick-next,
    .slick-prev {
        z-index: 200 !important;
    }
    .copy {
        float: right;
        clear: none;
        width: 75%;
        max-width: 600px;
        padding-top: 4em;
    }
    .image {
        float: left;
        width: 20%;
        margin-right: 5%;
    }
    /*
	.image > img{
		max-width:100%;
		min-width:100%;
	    height:auto;
	}
*/
    
    .tapbk {
        position: relative;
        .sticker {
            width: 70%;
            position: absolute;
            z-index: 1;
            top: 52.5%;
            left: 53%;
            img {
                margin-left: -50%
            }
        }
    }
    //end slide panel
}

// Panel
.contactpanel {
    padding: 4rem 0 4rem 0;
    overflow: hidden;
    @include container();
    @include center {}
    .form, .copy {
        @include column(12);
        clear: both;
    }
    @media only screen and (min-width: 640px) {
        .copy {
            @include column(4);
            clear: none;
        }
        .form {
            @include column(7);
            @include push(5);
            clear: none;
        }
    }
    $container_margin: 0%;
    .form-row {
        @include container();
        clear: both;
        margin-bottom: 1rem;
        /* overflow: hidden; */
        
        .col {
            @include column(12);
            clear: both;
        }
        .col-half {
            @include column(12);
            clear: both;
        }
        //.col-quart {@include column(12); clear:both; }
        .col-wide {
            @include column(12);
            clear: both;
        }
        @include generate_grid_positions ('.col', 12);
        //@include generate_grid_positions ('.col-quart', 12);
        @include generate_grid_positions ('.col-half', 12);
        @include generate_grid_positions ('.col-wide', 12);
        //@media only screen and (min-width: 850px)  {
        //.col {@include column(6); clear: none;}
        //.col-quart {@include column(6); clear:none; }
        //.col-half {@include column(6); clear: none;}
        //.col-wide {@include column(12); clear: none;}
        //@include generate_grid_positions ('.col', 6);
        //@include generate_grid_positions ('.col-quart', 6);
        //@include generate_grid_positions ('.col-half', 6);
        //@include generate_grid_positions ('.col-wide', 12);
        //}
        @media only screen and (min-width: 320px) {
            .destAddressBox, .homeButton, .destRegionBox {
                @include column(12);
            }
            @include generate_grid_positions ('.col', 12);
            .destAddressBox {
                @include column(5);
                clear: none;
            }
            .homeButton {
                @include column(2);
                @include push(5);
                clear: none;
            }
            .destRegionBox {
                @include column(5);
                @include push(7);
                clear: none;
            }
        }
        @media only screen and (min-width: 960px) {
            //.col-quart {@include column(3); clear:none; }
            .col-half {
                @include column(6);
                clear: none;
            }
            .col-wide {
                @include column(12);
                clear: none;
            }
            //@include generate_grid_positions ('.col-quart', 3);
            @include generate_grid_positions ('.col-half', 6);
            @include generate_grid_positions ('.col-wide', 12);
        }
        .col,
        .col-wide {
            overflow: visible!important;
        }
    }
    // form
    #eNewsFields {
        overflow: hidden;
        clear: both;
        padding: 1rem 0 1rem 0;
    }
    fieldset {
        border: none;
        padding: 0 0 0 0;
        margin: 0 0 0 0;
        legend {
            display: none;
        }
    }
    label {
        display: inline-block;
        width: 40%;
        color: #9f9f9f;
    }
    @media only screen and (max-width: 960px) {
        label {
            display: block;
            width: 100%;
            clear: both;
        }
    }
    select {
        width: 100%;
        margin: 0 0 0.5rem 0;
        color: #222;
        &:focus {
            outline: none;
        }
    }
    input[type='email'],
    input[type='text'] {
        color: darken($gray, 5%);
        background-color: rgba(0, 0, 0, .15);
        border: 1px solid transparent;
        padding: 0.5rem;
        margin: 0 0 0.5rem 0;
        width: 100%;
        opacity: 0.7;
        /* height: 34px; */
        
        &:focus {
            outline: none;
            opacity: 1.0;
            border-color: $tertiary;
            border: 1px solid $dark;
        }
    }
    @media only screen and (min-width: 960px) {
        //label {width: 42%;}
        select {
            width: 50%;
        }
        input[type='email'] {
            width: 75%;
        }
        input[type='text'] {
            width: 47%;
        }
    }
    textarea {
        opacity: 0.7;
        width: 97%;
        height: 4rem;
        margin: 0 0 0 0;
        border: 1px solid transparent;
        background-color: rgba(0, 0, 0, .15);
        padding: 1%;
        font-size: 100%;
        color: darken($gray, 5%);
        resize: vertical;
        &:focus {
            outline: none;
            border: 1px solid $tertiary;
            opacity: 1;
            border: 1px solid $dark;
        }
    }
    input[type='submit'] {
        border: none;
        color: #9f9f9f;
        background-color: $dark;
        padding: 0.5rem 1rem;
        &:hover {
            background-color: $tertiary;
            color: #fff;
        }
        &:focus {
            outline: none;
        }
    }
    .col-wide {
        label {
            width: 19%;
        }
        select {
            width: 75%;
        }
        input[type='text'] {
            width: 100%;
        }
        @media only screen and (min-width: 960px) {
            input[type='text'] {
                width: 75%;
            }
        }
        input[type='checkbox'] {
            margin: 0 0.5rem 0 0;
            &:focus {
                outline: none;
            }
        }
        /* select {margin: 0 0 0 1rem;} */
    }
    //subscribe
    .subscribe {
        label {
            width: auto;
        }
    }
    //errors
    .zemError {
        padding: 0 0 0 0;
        color: #e85647;
        strong {
            color: #e85647;
        }
        li {
            list-style: none;
        }
    }
    .loc-enter {
        /* padding: 2rem 2% 2rem 2%; */
        
        max-width: 1200px;
        margin: 0 auto;
        text-align: center;
        input[type='text'], button {
            color: $tertiary;
            border: 1px solid $dark;
            background-color: $dark;
            padding: 1rem;
            margin: 0 0 0.5rem 0;
            width: 100%;
            max-width: 60%;
            opacity: 0.6;
            float: left;
            &:focus {
                outline: none;
                opacity: 1.0;
                border-color: $tertiary;
            }
        }
        button {
            width: 100%;
            max-width: 25%;
            padding: 0.85rem;
            float: right;
        }
    }
    .browse {
        select {
            width: 98%
        }
    }
}

#mappanel {
    background-color: $secondary;
    border-bottom: 5px solid darken($secondary, 5%);
    width: 100%;
    z-index: 50;
    position: fixed;
    padding: 1rem 0 1rem 0;
    @include box-shadow(0 10px 15px 0 rgba(0, 0, 0, .3));
    overflow: hidden;
    .mapfilter {
        @include container();
        @include center();
    }
    select {
        width: 98%;
        margin: 0.6rem 0 0 0;
        color: #222;
    }
    .destAddressBox,
    .homeButton,
    .destRegionBox {
        @include column(12);
        clear: both;
        text-align: center
    }
    @media only screen and (min-width: 320px) {
        .destAddressBox {
            @include column(5);
            clear: none;
        }
        .homeButton {
            @include column(2);
            @include push(5);
            clear: none;
        }
        .destRegionBox {
            @include column(5);
            @include push(7);
            clear: none;
        }
    }
    // .mapfilter {padding:0.5rem 0; overflow:hidden;}
    .homeButton a {
        display: block;
        max-width: 140px;
        max-height: 43px;
        margin: 0 auto;
        opacity: .4;
    }
    &:active,
    &:hover {
        opacity: 1 !important;
    }
}
.centered{text-align:center !important;margin:-10px 0 20px 0;}
.show-me {
    color: $tertiary;
    border: 1px solid $dark;
    background-color: $dark;
    font-size: 50%;
    padding: 5px 10px;
    margin: 0 0 1.5rem 0;
    display: inline;
    width: auto;
    max-width: 300px;
    opacity: 0.6;
    text-align: center !important;
    font-weight: 400;
    position: relative;
    top: -5%;
    letter-spacing: .05rem;
    text-shadow:none;
    text-align:center;
    transition: all .3s ease;
    &:hover {
        outline: none;
        opacity: 1.0;
        border-color: $tertiary;
        text-decoration: none;
        color:white;
    }
}

$container_margin: 2%;
// ======================================================================
// Trade
// ======================================================================
.file-panel,
.file-box {
    @include container();
    @include center {}
}

.file-panel {
    padding-bottom: 2rem;
}

.filelist {
    padding: 0 0 0 0;
    margin: 0 0 0 0;
    li {
        list-style: none;
        margin: 0 0 0.5rem 0;
        a {
            text-decoration: none;
            color: $medium;
        }
        a:hover {
            text-decoration: none;
            color: $link-hover;
        }
    }
}

// ======================================================================
// Map
// ======================================================================
#map-container,
html,
body.map-pop {
    width: 100%;
    height: 100%;
}

.map-wrap {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
}

#map {
    position: relative;
    //padding-bottom: 56.25%;
    min-height: 100%;
    height: 100%;
    overflow: visible;
}

.info {
    background: #333;
    max-width: 200px;
    min-width: 200px;
    padding-bottom: 10px;
    .info-img {
        min-width: 100%;
        height: auto;
        margin-bottom: 20px;
    }
    .info-body > * {
        margin: .3rem 0;
    }
    .info-body a {
        text-decoration: none;
        opacity: .5;
        &:hover {
            opacity: 1;
        }
    }
}

// Seriously Ugly Hacks, but I can't really target these items otherwise
div.gm-style {
    [style*="background-color: rgb(255, 255, 255);"] {
        background: #333 !important;
        border-radius: 10px !important;
        width: 253px !important;
    }
    [style*="width: 13px;"] {
        border-radius: 10px !important;
        background: white !important;
        padding: 3px !important;
    }
    [style*="width: 244px;"] {
        width: 253px !important;
        margin-left: 10px;
    }
    [style*="width: 13px;"] img {
        margin: 3px !important;
    }
    [style*="transform: skew"] {
        border-radius: 0 !important;
        display: none !important;
    }
    [style*="box-shadow: 0px 1px 4px -1px rgba(0, 0, 0, 0.3);"] {
        background: none !important;
        box-shadow: none !important;
    }
    div[style*="color: rgb(204, 204, 204);"] {
        padding-top: 2px;
        opacity: .8;
        color: #ddd !important;
    }
    div[style*="color: rgb(204, 204, 204);"]:hover {
        opacity: 1;
        color: white !important;
    }
}

.timeline header#banner {
    display: none;
}

.timeline {
    & > .content {}
    .content {
        padding-top: 0;
        height: 75%;

		& > h1{
			text-align:center;
			padding-top:2rem;
		}        
        .content {
            padding: 0 !important;
            background:none !important;
        }
        .storyjs-embed {
            background: none !important;
            .vco-navigation {
                background-color: white !important;
                .timenav-interval-background {
                    background: white !important;
                    padding-bottom: 100px !important;
                }
            }
            & > div {
                background: none !important;
            }
            .nav-container {
                color: rgba(0, 0, 0, .5);
                &:hover {
                    color: rgba(0, 0, 0, .8);
                }
            }
        }
    }
}


.article-image{margin-bottom:2rem;}
.details {
margin:1rem 0;
    text-align:center;
font-size:85%;
p{margin-bottom:0;}
}

    .panelleft .copy h1{
    text-align:center !important;
    }
.organizations .content .item{
    .panelleft{padding-top:1rem;}
    h1,
    h2{
        text-align:center !important;
        }
      h1{padding-top:2rem;}
    }
