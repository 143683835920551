/**
 * source: hint-always.scss
 *
 * Defines a persisted tooltip which shows always.
 *
 * Classes added:
 * 	1) hint--always
 *
 */

.#{$prefix}always {
	&:after, &:before {
		opacity: 1;
		visibility: visible;
	}

	&.#{$prefix}top {
		@include set-margin('translateY', -1);
	}

	&.#{$prefix}bottom {
		@include set-margin('translateY', 1);
	}

	&.#{$prefix}left {
		@include set-margin('translateX', -1);
	}

	&.#{$prefix}right {
		@include set-margin('translateX', 1);
	}
}

