/**
 * source: hint-position.scss
 *
 * Defines the positoning logic for the tooltips.
 *
 * Classes added:
 * 	1) hint--top
 * 	2) hint--bottom
 * 	3) hint--left
 * 	4) hint--right
 */

@mixin vertical-positioned-tooltip($propertyY, $transitionDirection) {
	&:before {
		// get the arrow out
		margin-#{$propertyY}: -2 * $arrowBorderWidth;
	}

	&:after {
		// bring back the tooltip by some offset so that arrow doesn't stick at end
		margin-left: -1 * $arrowOffsetX;
	}

	&:before, &:after {
		#{$propertyY}: 100%;
		left: 50%;
	}

	&:hover, &:focus {
		@include set-margin('translateY', $transitionDirection);
	}
}

@mixin horizontal-positioned-tooltip($propertyX, $transitionDirection) {
	&:before {
		// get the arrow out
		margin-#{$propertyX}: -2 * $arrowBorderWidth;
		// bring back to center
		margin-bottom: -1 * $arrowBorderWidth;
	}

	&:after {
		// bring back to center
		margin-bottom: -1 * floor($tooltipHeight / 2);
	}

	&:before, &:after {
		#{$propertyX}: 100%;
		bottom: 50%;
	}

	&:hover, &:focus {
		@include set-margin('translateX', $transitionDirection);
	}
}


/**
 * set default color for tooltip arrows
 */
@include arrow-border-color($defaultColor, 'false');

/**
 * top tooltip
 */
.#{$prefix}top {
	@include vertical-positioned-tooltip('bottom', -1);
}

/**
 * bottom tooltip
 */
.#{$prefix}bottom {
	@include vertical-positioned-tooltip('top', 1);
}

/**
 * right tooltip
 */
.#{$prefix}right {
	@include horizontal-positioned-tooltip('left', 1);
}

/**
 * left tooltip
 */
.#{$prefix}left {
	@include horizontal-positioned-tooltip('right', -1);
}
