// hint-variables.scss
//
// Declares some variables used within the library.

// Prefix for all classes. By default, BEM naming convention is used
$prefix: 'hint--' !default;

// font size
$fontSize: 12px;

// paddings
$verticalPadding: 8px;
$horizontalPadding: 10px;

// default tooltip height
$tooltipHeight: $fontSize + 2 * $verticalPadding !default;

// border-width for tooltip arrow
$arrowBorderWidth: 6px !default;

// horizontal arrow offset
$arrowOffsetX: 3 * $arrowBorderWidth !default;

// text-shadow darken percentage
$textShadowDarkenAmount: 25% !default;

// transition distance
$transitionDistance: 8px !default;

// Delay in showing the tooltips.
$showDelay: 100ms !default;

// Delay in hiding the tooltips.
$hideDelay: 0ms !default;

// z-index for tooltips
$zIndex: 1000000 !default;


// Various colors
// Default color is blackish
$defaultColor: hsl(0, 0%, 22%) !default;

// Error color
$errorColor: hsl(1, 40%, 50%) !default;

// Warning color
$warningColor: hsl(38, 46%, 54%) !default;

// Info Color
$infoColor: hsl(200, 50%, 45%) !default;

// Success Color
$successColor: hsl(121, 32%, 40%) !default;
